import * as React from "react";
import "/src/styling/home.css";
import { navigate } from "gatsby";

import Projects from "../components/projects";
import Navbar from "../components/navbar";

const banner = () => {
    return `

██████╗ ██╗ ██████╗██╗  ██╗ █████╗ ███╗   ██╗    ██╗     ██╗
██╔══██╗██║██╔════╝██║ ██╔╝██╔══██╗████╗  ██║    ██║     ██║
██████╔╝██║██║     █████╔╝ ███████║██╔██╗ ██║    ██║     ██║
██╔══██╗██║██║     ██╔═██╗ ██╔══██║██║╚██╗██║    ██║     ██║
██║  ██║██║╚██████╗██║  ██╗██║  ██║██║ ╚████║    ███████╗██║
╚═╝  ╚═╝╚═╝ ╚═════╝╚═╝  ╚═╝╚═╝  ╚═╝╚═╝  ╚═══╝    ╚══════╝╚═╝

  `;
};

// markup
const IndexPage = () => {
    return (
        <div>
            {/* Navbar */}
            <Navbar />
            <main>
                {/* Intro */}
                <div className="intro">
                    <div id="intro_p">
                        <h1>
                            Hello! I'm a recent Computer&nbsp;Science
                            graduate&nbsp;from&nbsp;UCSD.
                        </h1>
                        <p>
                            My name is Rickan — I also go by Ricky — and I'm
                            currently looking for a software engineering
                            position. I was fortunate to have the opportunity to
                            explore a variety of CS disciplines during
                            undergrad; and would say Computer&nbsp;Vision,
                            Parallel&nbsp;Computing, and
                            Computational&nbsp;Complexity are a few of the most
                            memorable.
                        </p>
                    </div>
                    {/* terminal style site button*/}
                    <div
                        className={"terminal_view"}
                        onClick={() => {
                            navigate("/terminal/");
                        }}
                    >
                        <div className="terminal">
                            <pre>
                                <span className="banner">{banner()}</span>
                            </pre>
                            <div className="ssh">
                                <span className="g">@rickyli/guest:</span>
                                <span className="v">
                                    $ ~ click to ssh rickyli/terminal&nbsp;
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                {/* /End Intro */}

                {/* Projects */}
                <Projects />
                {/* /End Projects */}
            </main>
        </div>
    );
};

export const Head = () => {
    return (
        <>
            <title>Rickan Li</title>
            <meta name="description" content="Rickan Li Personal Site" />
            <meta charSet="utf-8" />
            <meta
                name="viewport"
                content="width=device-width, initial-scale=1"
            />
            <link
                rel="icon"
                type="image/png"
                sizes="245x245"
                href="../images/icons/icon_AV.png"
            />
            <link
                rel="apple-touch-icon"
                type="image/png"
                href="../images/icons/icon_AV.png"
            />
        </>
    );
};

export default IndexPage;
