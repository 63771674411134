import * as React from "react";
import menu_icon from "../../static/images/icons/bars-solid.svg";
import close_icon from "../../static/images/icons/xmark-solid.svg";
import Resume from "./resume";
import { openResume } from "./resume";

export default function MobileNavbar() {
    const [mobToggle, setMobToggle] = React.useState(false);
    const { open, toggle } = openResume();

    React.useEffect(() => {
        mobToggle
            ? (document.body.style.overflow = "hidden")
            : (document.body.style.overflow = "auto");
    });
    return (
        <header className="mobile-navbar">
            <div className="toggle">
                <a className="name" href="/">
                    rickan li
                </a>
                <button
                    className="toggle-button"
                    onClick={() => setMobToggle(!mobToggle)}
                >
                    <img src={menu_icon} alt="Menu icon" />
                </button>
            </div>
            {mobToggle && (
                <div className="nav-overlay">
                    <button
                        className="toggle-button"
                        onClick={() => setMobToggle(!mobToggle)}
                    >
                        <img src={close_icon} alt="Menu icon" />
                    </button>
                    <nav className="mobile-nav">
                        <a
                            className="a_site"
                            href="https://www.linkedin.com/in/rickanli"
                        >
                            linkedin
                        </a>
                        <a
                            className="a_site"
                            href="https://github.com/rickyli888"
                        >
                            github
                        </a>
                        <React.Fragment>
                            <button
                                className="mobile-res-button"
                                onClick={toggle}
                            >
                                resume
                            </button>
                            <Resume isShown={open} onClose={toggle} />
                        </React.Fragment>
                    </nav>
                </div>
            )}
        </header>
    );
}
