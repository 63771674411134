import React, { useState } from "react";
import ReactDOM from "react-dom";
import "/src/styling/res.css";

export const openResume = () => {
    const [open, setOpen] = React.useState<boolean>(false);
    const toggle = () => setOpen(!open);
    return {
        open,
        toggle,
    };
};

interface ModalProps {
    isShown: boolean;
    onClose: () => void;
}

export default function Resume({ isShown, onClose }: ModalProps) {
    const keydownHandler = (key: KeyboardEvent) => {
        switch (key.key && isShown) {
            case key.key === "Escape" && isShown:
                onClose();
                break;
            default:
        }
    };

    React.useEffect(() => {
        isShown
            ? (document.body.style.overflow = "hidden")
            : (document.body.style.overflow = "auto");
        if (isShown) {
            document.addEventListener("keydown", keydownHandler);
        }
        return () => document.removeEventListener("keydown", keydownHandler);
    });

    const resume = (
        <div className="resumeContainer" onClick={onClose}>
            <div
                className="resume_content"
                onClick={(e) => e.stopPropagation()}
            >
                <button
                    className="pdf"
                    role="button"
                    onClick={() => {
                        window.open(
                            "https://drive.google.com/file/d/1UQI1mbhLVIyjgbSj_8ix8tGFo9foO2Tq/view?usp=sharing"
                        );
                    }}
                >
                    PDF
                </button>
                <div className="res_header">
                    <h1>Rickan (Ricky) Li</h1>
                    <span>
                        <p>rickyli860@gmail.com</p>
                        <div className="vl"></div>
                        <p>github.com/rickyli888</p>
                        <div className="vl"></div>
                        <p>linkedin.com/in/rickanli</p>
                    </span>
                </div>
                <div className={"res_sections"}>
                    <div className="Education">
                        <h2>Education</h2>
                        <div className="ed_section">
                            <div className={"college"}>
                                <span>
                                    <h3>University of California, San Diego</h3>
                                    <div className="vl"></div>
                                    <p>Revelle College</p>
                                    <div className="vl"></div>
                                    <p>Bachelors of Computer Science</p>
                                </span>
                                <span id={"years"}>
                                    <p>Fall 2017 - Winter 2022</p>
                                </span>
                            </div>
                            <div className={"courses"}>
                                <h3>Relevant Coursework:</h3>
                                <span>
                                    Sofware Engineering, Principles of Operating
                                    Systems, Principles of Programming
                                    Languages, Parallel Computing, Computer
                                    Vision, Deep Learning, Human-AI Interaction,
                                    Recommender Systems, Database System
                                    Principles, Web Client Languages, Computer
                                    Networks, Security and Cryptography,
                                    Statistical Methods, Computer Architecture,
                                    Computer Graphics
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="Projects">
                        <h2>Projects</h2>
                        <div className="proj_section">
                            <div className={"proj_item"}>
                                <span className="pi">
                                    <h3>Tohnao</h3>
                                    <h4>2022</h4>
                                </span>
                                <p>
                                    I wanted to build an integrated note-taking
                                    system that would make it easier to
                                    facilitate knowledge management. The core
                                    idea was to have a text editor with built-in
                                    features that helped with linking notes and
                                    meta-information while maintaining a
                                    customizable interface and typography. I
                                    also thought that including an OCR system
                                    would help enhance its use as knowledge
                                    management and alleviate workflow issues.
                                </p>
                            </div>
                            <div className={"proj_item"}>
                                <span className="pi">
                                    <h3>Hyphy</h3>
                                    <h4>2021-2022</h4>
                                </span>
                                <p>
                                    Worked on designing mockups and developing
                                    UI/UX along with testing strategies. Also
                                    prototyped events and location features,
                                    with a focus on optimization and
                                    consumer-first practices. Hyphy is a social
                                    media startup that opposes current trends;
                                    whereas, the goal is for you to have less
                                    screen time. The app seeks to facilitate
                                    making memories through coordinating
                                    real-life events and more impromptu moments.
                                </p>
                            </div>
                            <div className={"proj_item"}>
                                <span className="pi">
                                    <h3>ReadySetCook!</h3>
                                    <h4>2020</h4>
                                </span>
                                <p>
                                    A mobile app that integrated inventory
                                    management and recipe recommendations for my
                                    Software Engineering class (CSE 110). It was
                                    extremely memorable as it was the beginning
                                    of the COVID lockdowns. Working in a team of
                                    eleven, it served a valuable part in the
                                    development of my communication skills and
                                    learning team management. The project was my
                                    introduction to Dart and Flutter; it tested
                                    my abilities in software design, scaling
                                    applications, and adaptability.
                                </p>
                            </div>
                            <div className={"proj_item"}>
                                <span className="pi">
                                    <h3>NES Emulator</h3>
                                    <h4>2020</h4>
                                </span>
                                <p>
                                    Wrote an emulator that simulated the NES
                                    platform in Rust. I was inspired by UCSD’s
                                    virtualization of CentOS for browsers on
                                    their cloud service along with taking System
                                    Architecture at the time. It was also a good
                                    opportunity to work with Rust and
                                    hardware/software management- and was a nice
                                    environement to apply concurrent
                                    programming. The nostalgia element along
                                    with the feedback of being able to see/play
                                    games added to the learning experience.
                                    Further improvements in audio processing and
                                    optimization in the compiled WebAssembly.
                                </p>
                            </div>
                            <div className={"proj_item"}>
                                <h3>Other Experiences:</h3>
                                <p>
                                    SWISP (LISP language implemented with
                                    Swift), jenny (Music Recommmender), Inhua
                                    (Markdown Editor built on Svelte)
                                </p>
                                <p>
                                    Forage Virtual Internship Experience( J.P.
                                    Morgan, Y-Combinator, Electronic Arts, Lyft
                                    )
                                </p>
                                <p>
                                    <span>Clubs and Organizations: </span>
                                    CSES, IEEE, ACM, and Pi Kappa Alpha
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="Technologies">
                        <h2>Technologies</h2>
                        <div className="tech_section">
                            <div className={"tech_item"}>
                                <h3>Languages:</h3>
                                <p>
                                    Java/Kotlin, Python, Dart, C/C++, Swift,
                                    JavaScript/TypeScript, Rust, Haskell, SQL
                                </p>
                            </div>
                            <div className={"tech_item"}>
                                <h3>Frameworks/Tools:</h3>
                                <p>
                                    React, Flutter, PyTorch/ Tensorflow,
                                    Flutter, Firebase, Docker, Kubernetes
                                </p>
                            </div>
                            <div className={"tech_item"}>
                                <h3>Design:</h3>
                                <p>
                                    Adobe Suite (Illustrator, InDesign,
                                    Photoshop), Figma
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );

    return isShown ? ReactDOM.createPortal(resume, document.body) : null;
}
