export const projectData = [
    {
        id: 0,
        name: "Tohnao",
        description:
            "I wanted to build an integrated note-taking system that would make it easier to facilitate knowledge" +
            " management.\n\n" +
            "The core idea was to have a text editor with built-in features that helped with linking notes and meta-information" +
            " while maintaining a customizable interface and typography." +
            " I also thought that including an OCR" +
            " system would " +
            "help enhance its use as knowledge management and alleviate workflow issues.",
        image: "../images/projects/tohnao.png",
    },
    {
        id: 1,
        name: "Hyphy",
        description:
            "Worked on designing mockups and developing UI/UX along with testing strategies. Also prototyped events and location features, with a focus on optimization and consumer-first practices. Hyphy is a social media startup that opposes current trends; whereas, the goal is for you to have less screen time. The app seeks to facilitate making memories through coordinating real-life events and more impromptu moments.",
        image: "../images/projects/hyphy.png",
    },
    {
        id: 2,
        name: "ReadySetCook!",
        description:
            "A mobile app that integrated inventory management and recipe recommendations made for my Software " +
            "Engineering class (CSE 110). It was extremely memorable as it was the beginning of the COVID lockdowns." +
            "\n\nWith working in a team of eleven, it served as a valuable part in the development of my" +
            " communication" +
            " skills and other" +
            "key skills like software design and team management. It was also my introduction to Dart and Flutter.",
        image: "../images/projects/readysetcook.png",
    },
    {
        id: 3,
        name: "jenny",
        description:
            "The goal of the project is to create a more feature-defined music recommender, where the user can select more specific characteristics in finding similar songs.\n" +
            "\nThe composition of music lends itself to have familiar qualities, but it’s something I just can't " +
            " explain." +
            "The term “je ne sais quoi” encapsulates this feeling where I can’t really describe if asked to" +
            " articulate or explain, but it’s not a negative thing. I often have these inexplicable moments when listening to music. The name “jenny” is taken from the same French-term, when I was trying to think of a name while sounding random phonetics that I could think of. \n" +
            "\n" +
            "For somebody that’s not musically inclined, trying to remember something based off memories of melodies and rhythm is near-impossible. I also have the problem of repetitively listening to a song until I grow sick of it. However, certain characteristics and metrics that made the song enjoyable to me, are still very much enjoyable. \n" +
            "\n" +
            "A lot of platforms have recommender systems that can play a radio or create a playlist of similar songs, but I often find them lacking or just not getting the qualities I’m looking for right. An example would be a remix or “flipped” version of song could change which genre or certain categories that a song would fit in, but recommendations often reflect the original metrics that said artist fit in.",
        image: "../images/projects/jenny.png",
    },
    {
        id: 4,
        name: "LISP Interpreter",
        description:
            "Learning about how programming languages worked, and discussing the process of creating languages for specific paradigms, gave me a lot of “Ohhh, so that’s why” moments. I also developed a deeper appreciation for Computer Science and the logic that it recruits. It also inspired a lot current interest I have with Natural Language Processing and linguistics in regards to cognition and Machine Learning.\n" +
            "\n" +
            "This project was an extension of my interests. Through it, I explored the processes of lexical" +
            " analysis, creating programming syntax, and symbolic expressions. The interpreter is written in Swift. I" +
            " want to expand the project based on certain inspiration projects, where semantics are drawn from" +
            " specific people (i.e. ArnoldC). I’m in the process" +
            " of creating a “Steven Seagal”-based language.",
        image: "../images/projects/SWIFT LISP.png",
    },
    {
        id: 5,
        name: "Rust NES Emulator",
        description:
            "Wrote an emulator that simulated the NES platform in Rust. I had the Catch-22 of getting a new computer for my" +
            "classes, with it not being able to run a majority of programs needed for said classes.\nFortunately," +
            " UCSD’s cloud " +
            "computing had implemented virtualization that could run the necessary OS’s, essentially, in-browser. I" +
            " was also " +
            "taking System Design and Computer Architecture, so I was fascinated by how emulators worked and wanted a" +
            "more hands-on experience.",
        image: "../images/projects/pac_nes.png",
    },
];
