import * as React from "react";
import Resume from "./resume";
import { openResume } from "./resume";
import MobileNavbar from "./mobile_navbar";

import "/src/styling/nav.css";
import "/src/styling/mobile_nav.css";

function Navbar() {
    const { open, toggle } = openResume();

    return (
        <>
            <MobileNavbar />
            <header className="fixed-top">
                <nav className="nav_b">
                    <a className="name" href="/">
                        rickan li
                    </a>
                    <a
                        className="a_site"
                        href="https://www.linkedin.com/in/rickanli"
                    >
                        linkedin
                    </a>
                    <a className="a_site" href="https://github.com/rickyli888">
                        github
                    </a>
                    <React.Fragment>
                        <button
                            className="a_button"
                            id="resume"
                            onClick={toggle}
                        >
                            resume
                        </button>
                        <Resume isShown={open} onClose={toggle} />
                    </React.Fragment>
                </nav>
            </header>
        </>
    );
}
export default Navbar;
