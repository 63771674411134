import * as React from "react";
import { projectData } from "./projectInfo";

import "/src/styling/project.css";
import "/src/styling/proj_modal.css";
import ReactDOM from "react-dom";

import { tohnao } from "../../static/images/projects/tohnao.png";

interface project {
    id: number;
    name: string;
    description: string;
    image: string;
}

interface Cards {
    cards: Array<project>;
}

const ProjectCards = ({ cards }: Cards) => {
    const [active, setActive] = React.useState<number>(7);

    const escKey = (key: KeyboardEvent) => {
        switch (key.key && active !== 7) {
            case key.key === "Escape" && true:
                setActive(7);
                break;
            default:
        }
    };

    React.useEffect(() => {
        active !== 7
            ? (document.body.style.overflow = "hidden")
            : (document.body.style.overflow = "auto");

        document.addEventListener("keydown", escKey);
        return () => document.removeEventListener("keydown", escKey);
    });

    const project_modal = () => {
        return active !== 7
            ? ReactDOM.createPortal(
                  <div
                      className="pro_modal_container"
                      onClick={() => setActive(7)}
                  >
                      <div
                          className="pro_modal_content"
                          onClick={(e) => e.stopPropagation()}
                      >
                          <div className="proj_image">
                              <img src={cards[active].image} alt="Project" />
                          </div>
                          <div className={"project_text"}>
                              <div className="proj_header">
                                  <h1>{cards[active].name}</h1>
                              </div>
                              <div className="proj_desc">
                                  <p>{cards[active].description}</p>
                              </div>
                          </div>
                      </div>
                  </div>,
                  document.body
              )
            : null;
    };

    return (
        <div className="project_cards">
            {cards.map((card: project) => (
                <div
                    className="card"
                    key={`key` + card.id}
                    onClick={() => setActive(card.id)}
                >
                    <div className="overlay">
                        <div className={"bgImage"}>
                            <img src={card.image} alt="Project" />
                        </div>
                        <div className="text_overlay">
                            <h2>{card.name}</h2>
                        </div>
                    </div>
                </div>
            ))}
            {project_modal()}
        </div>
    );
};

class Projects extends React.Component {
    render() {
        return (
            <React.Fragment>
                <div id="projects_container">
                    <div id="proj_header">
                        <h1>Projects.</h1>
                        <p> [Personal projects done in the last four years]</p>
                    </div>
                    <ProjectCards cards={projectData} />
                </div>
            </React.Fragment>
        );
    }
}
export default Projects;
